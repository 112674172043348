<template>
    <div class="main-layout-section admin-portal secondary">
        <AdminHeader @setPodData="setPodData($event)" />
        
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeIn animate__fast">Manage Your Labs</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link to="/admin/dashboard" class="admin-general-section-button">
                            Back To Dashboard
                        </router-link>
                        <router-link to="/admin/labs/deploy" class="admin-general-section-button">
                            Deploy New Lab
                        </router-link>
                    </div>

                    <div class="col-sm-12">
                        <div class="overflow-auto p-0 m-0">
                            <b-table
                            id="admin-general-table"
                            :striped="true"
                            :hover="false"
                            :dark="false"
                            :items="labs"
                            :fields="fields"
                            show-empty
                            :emptyText="emptyText"
                            >
                                <template #cell(admin_mode)="data">
                                    <label class="lockout-switch">
                                        <input type="checkbox" 
                                        :ref="data.item.id+'_admin_mode'" 
                                        v-model="labs[data.index].admin_mode" 
                                        @change="handleAdminMode(data.item.id, data.index)"
                                        >
                                        <span class="lockout-slider lockout-round"></span>
                                    </label>
                                </template>
                                <template #cell(action)="data">
                                    <div class="icon-action-buttons-container d-flex flex-row">
                                        <router-link :to="`/admin/labs/${data.item.id}/pods`">
                                            <b-icon class="icon" :icon="data.value"></b-icon>
                                            View Pods
                                        </router-link>
                                        <!-- edit button -->
                                        <router-link :to="`/admin/labs/edit/${data.item.id}`" :class="data.value" class="btn-sm mx-2">
                                            <b-icon class="icon" icon="pencil-fill"></b-icon>
                                            Edit Lab
                                        </router-link>
                                    </div>
                                </template>
                                <template #empty="scope">
                                    <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetLabsList, adminHandleLabAdminMode } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";

export default {
  name: "AdminLabs-view",
  components: { AdminHeader, AdminFooter },
  data() {
    return {
      emptyText:"No labs found at the moment!",
      emptyHtml:"No labs found at the moment!",
      fields: [{'key' : 'id', 'label' : 'ID'}, {'key' : 'value', 'label' : 'Name'}, {'key' : 'admin_mode', 'label' : 'Admin Mode'}, {'key' : 'action', 'label' : 'Action'}],
      labs: [],
    };
  },

  computed: {
    logo_file() {
        return this.$store.getters.getThemeLogo().logo;
    }
  },

  methods: {
    labsList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        client_id: user_data.client_id
      };
      adminGetLabsList(params).then(response => {
        this.labs = response.data.labs;
        if(this.labs.length > 0) {
          this.labs.map(function(lb){
            return lb.action='eye-fill';
          });
        }
      });
    },

    setPodData(obj) {
        this[obj.key] = obj.value;
    },

    handleAdminMode(lab_id, index) {
        let value = this.$refs[lab_id+'_admin_mode'].checked;
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            lab_id: lab_id,
            admin_mode: (value ? 1 : 0)
        };
        adminHandleLabAdminMode(params).then(response => {
            if(response.status) {
                this.makeToast("success", response.message);
            }
            else {
                this.labs[index].admin_mode = !params.admin_mode;
                this.makeToast("error", response.message);
            }
        });
    }
  },

  mounted() {
    this.labsList();
  }

};
</script>
<style scoped>
.lockout-slider:before {
    content: "";
    background-color: var(--client-hover); 
}
.lockout-switch input:checked + .lockout-slider:before {
    content: "";
    background-color: var(--client-primary);
}
</style>