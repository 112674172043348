<template>
    <div class="main-layout-section admin-portal secondary">
        <AdminHeader @setPodData="setPodData($event)" />
        
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeIn animate__fast">Manage Your Pods</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">

                    <div class="admin-general-section-buttons">
                        <router-link to="/admin/labs" class="admin-general-section-button">
                            Back To Labs Management
                        </router-link>
                        <router-link :to="`/admin/labs/${lab_id}/pods/deploy`" class="admin-general-section-button">
                            Deploy New Pod
                        </router-link>
                    </div>

                    <div class="col-sm-12">
                        <div class="overflow-auto p-0 m-0">
                            <b-table
                            id="admin-general-table"
                            :striped="true"
                            :hover="false"
                            :dark="false"
                            :items="pods"
                            :fields="fields"
                            show-empty
                            :emptyText="emptyText"
                            >
                                <template #cell(value)="data">
                                    <div class="pod-key-container">
                                        <template v-if="data.item.show_key">
                                            <label>{{ data.item.pod_id_key }}</label>
                                            <span class="fa fa-eye-slash" @click="data.item.show_key = false"></span>
                                        </template>
                                        <template v-if="!data.item.show_key">
                                            <label>{{ data.item.pod_id_masked }}</label>
                                            <span class="fa fa-eye" @click="data.item.show_key = true"></span>
                                        </template>
                                    </div>
                                </template>

                                <template #cell(lock_out)="data">
                                    <!-- <div class="icon-action-buttons-container d-flex flex-row"> -->
                                        <!-- <switches 
                                        class="active-switch h-53 lockout-switch" 
                                        :value="data.item.lock_out"
                                        theme="bootstrap" 
                                        color="primary"
                                        type-bold="true"
                                        label="" 
                                        :ref="data.item.pod_id_key"
                                        v-model="pods[data.index].lock_out" 
                                        @update:modelValue="handleLockUnlock(data.item.pod_id_key)"
                                        >
                                        </switches> -->

                                        <label class="lockout-switch">
                                            <input type="checkbox" 
                                            :ref="data.item.pod_id_key+'_lockout'" 
                                            v-model="pods[data.index].lock_out" 
                                            @change="handleLockUnlock(data.item.pod_id_key)"
                                            >
                                            <span class="lockout-slider lockout-round"></span>
                                        </label>
                                    <!-- </div> -->
                                </template>
                                <template #cell(action)="data">
                                <div class="icon-action-buttons-container d-flex flex-row">
                                    <!-- lock/unlock button -->
                                    <!-- <button 
                                    class="btn-sm" 
                                    :class="data.value" 
                                    v-if="data.item.lock_out == 0"
                                    @click="commandHandle(1, 'lockout', data.item.pod_id_key)"
                                    >
                                    <b-icon class="icon" icon="lock-fill"></b-icon>
                                    Lockout
                                    </button>
                                    <button 
                                    class="btn-sm" 
                                    :class="data.value" 
                                    v-if="data.item.lock_out == 1"
                                    @click="commandHandle(1, 'unlockout', data.item.pod_id_key)"
                                    >
                                    <b-icon class="icon" icon="unlock-fill"></b-icon>
                                    UnLockout
                                    </button> -->

                                    <!-- logout button -->
                                    <button 
                                    class="btn-sm" 
                                    :class="data.value"
                                    @click="commandHandle(1, 'logout', data.item.pod_id_key)"
                                    >
                                    <b-icon class="icon" icon="power"></b-icon>
                                    Logout
                                    </button>

                                    <!-- reboot button -->
                                    <button 
                                    class="btn-sm" 
                                    :class="data.value"
                                    @click="commandHandle(1, 'reboot', data.item.pod_id_key)"
                                    >
                                    <b-icon class="icon" icon="bootstrap-reboot"></b-icon>
                                    Reboot
                                    </button>

                                    <!-- edit button -->
                                    <button 
                                    class="btn-sm" 
                                    :class="data.value"
                                    @click="ledModal(data.item.pod_id_key)"
                                    >
                                    <b-icon class="icon" icon="lightbulb-fill"></b-icon>
                                    LED Defaults
                                    </button>

                                    <!-- edit button -->
                                    <router-link :to="`/admin/labs/${lab_id}/pods/${data.item.id}`" :class="data.value" class="btn-sm">
                                        <b-icon class="icon" icon="pencil-fill"></b-icon>
                                        Edit
                                    </router-link>
                                    
                                    <!-- delete button -->
                                    <button 
                                    class="btn-danger btn-sm" 
                                    :class="data.value"
                                    @click="confirmDelete(data.item.id)"
                                    >
                                    <b-icon class="icon" icon="trash2-fill"></b-icon>
                                    Delete
                                    </button>
                                </div>
                                </template>
                                <template #cell(connected)="data">
                                    <i class="fas fa-circle-check fa-2xl icon h2" v-if="data.item.activity !== 'MISSING' && data.item.activity !== 'IDLE'"></i>
                                    <i class="fas fa-times-circle fa-2xl icon h2" v-if="data.item.activity === 'MISSING'" style="color:#FF1744;"></i>
                                </template>
                                <template #cell(online)="data">
                                    <i class="fas fa-circle-check fa-2xl icon h2" v-if="data.item.pod.OnlineDetail.online == 1" v-bind:id="data.item.pod_id_key + '-tooltip'" style="color: #1ba10d;"></i>
                                    <i class="fas fa-times-circle fa-2xl icon h2" v-if="data.item.pod.OnlineDetail.online !== 1" style="color:#FF1744;" v-bind:id="data.item.pod_id_key + '-tooltip'"></i>
                                    <b-tooltip v-bind:target="data.item.pod_id_key + '-tooltip'" triggers="hover" placement="top">
                                        {{ data.item.pod.OnlineDetail.message }}
                                    </b-tooltip>
                                </template>
                                <template #cell(thumbnail)="data">
                                    <template v-if="data.item.pod.ScreenshotDetail.age >= 0 && data.item.pod.ScreenshotDetail.age <= 60">
                                        <img :src="`${data.item.pod.ScreenshotDetail.url}/${data.item.pod.ScreenshotDetail.image}`" style="height: 45px;border-radius: 4px;" @click="screenShotModal(data.item.pod_id_key, data.item.pod.ScreenshotDetail)" />
                                    </template>
                                    <template v-else>
                                        <span>{{ data.item.pod.ScreenshotDetail.message }}</span>
                                    </template>
                                </template>
                                <template #empty="scope">
                                <p class="text-center p-0 m-0">{{ scope.emptyText }}</p>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
        
        <!-- start of delete confirm modal -->
        <b-modal id="delete-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Confirm
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <b-overlay :show="false" rounded="lg">
                <div class="d-flex">
                <p>Are you sure you want to permanently delete this Gamepod?</p>
                </div>
                <div class="d-flex text-center">
                <button
                    type="button"
                    class="modal-button px-5 py-2"
                    @click="deletePod"
                >
                    Yes
                </button>
                <button
                    type="button"
                    class="modal-button px-5 py-2 mx-2"
                    @click="$bvModal.hide('delete-modal')"
                >
                    No
                </button>
                </div>
            </b-overlay>
        </b-modal>

        <!-- start of pallets modal -->
        <b-modal id="pallets-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Update LED Settings
            </template>
            <div class="d-block text-center theme-color-buttons">
                <button
                    v-for="clr in rgb_pallets"
                    :key="clr.id"
                    type="button"
                    class="btn bg mb-3"
                    :style="{ background: clr.value, color: '#fff' }"
                    :class="[ led_state !== clr.id ? 'theme-opacity' : '' ]"
                    @click="selectUnselectLED(clr.id)"
                >
                    {{ clr.value.toUpperCase() }}
                </button>
            </div>
            <div class="d-flex" v-if="led_state !== ''">
                <button 
                class="modal-button w-100"
                @click="commandHandle(led_state, 'led1', selected_pod_id)"
                >Change LED1</button>
            </div>
            <div class="d-flex mt-2" v-if="led_state !== ''">
                <button 
                class="modal-button w-100"
                @click="commandHandle(led_state, 'led2', selected_pod_id)"
                >Change LED2</button>
            </div>
        </b-modal>

        <!-- start of screenshot modal -->
        <b-modal id="screenshot-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer hide-header size="xl">
            <!-- <div class="auto-close-screenshot" @click="$bvModal.hide('screenshot-modal')"></div> -->
            <template #modal-title>
                Pod Thumbnail
                <b-icon 
                class="icon" 
                icon="question-circle-fill"
                >
                </b-icon> 
            </template>
            <template v-if="screenshot_pod_id !== ''">

                <template v-if="screenshot_pod.age >= 0 && screenshot_pod.age <= 60">
                    <!-- <div class="d-flex thumbnail-box" :style="{background:`url(${thumbnail_image_url})`}"></div> -->
                    <div class="d-flex thumbnail-box" @click="$bvModal.hide('screenshot-modal')">
                        <img :src="`${thumbnail_image_url}`" @click="$bvModal.hide('screenshot-modal')" style="width: 100%;height: 100%;object-fit: contain;user-select: all;pointer-events: all;-webkit-user-drag: auto;" />
                    </div>
                    <!-- <img :src="`${screenshot_pod.url}/${screenshot_pod.image}?v=${(new Date()).now}`" style="    width: 100%;max-height: 400px;"> -->
                </template>
                <template v-else>
                    <div class="d-flex" style="width: 100%;height: 100%;justify-content: center;align-items: center;" @click="$bvModal.hide('screenshot-modal')">
                        <span>{{ screenshot_pod.message }}</span>
                    </div>
                </template>
            </template>
        </b-modal>

    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetPodsList, adminRunCommand, adminDeletePod, adminGetPalletsList, adminGetPodScreenshot } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";
import { setFlaskPodDisabled } from "../../services/FlaskService";
// import Switches from 'vue-switches';

export default {
  name: "AdminLabs-view",
  components: { AdminHeader, AdminFooter },
  data() {
    return {
      lab_id: this.$route.params.lab_id,
      emptyText:"No pods found at the moment!",
      emptyHtml:"No pods found at the moment!",
      fields: [
        {'key' : 'value', 'label' : 'Key'},
        {'key' : 'license', 'label' : 'Nav'}, 
        {'key' : 'activity', 'label' : 'Activity'},
        {'key' : 'connected', 'label' : 'Connected'}, 
        {'key' : 'lock_out', 'label' : 'Status'}, 
        {'key' : 'online', 'label' : 'Online'},
        {'key' : 'thumbnail', 'label' : 'Thumbnail'},
        {'key' : 'action', 'label' : 'Action'}
      ],
      pods: [],
      command_types: {
        'lockout' : 'lockout', 
        'unlockout' : 'unlockout', 
        'logout' : 'logout', 
        'reboot' : 'reboot',
        'led1' : 'led1',
        'led2' : 'led2',
      },
      delete_pod_id: "",
      selected_pod_id:"",
      rgb_pallets : [],
      led_state: "",
      pods_poller: null,
      pods_api_state: false,
      screenshot_pod_id: "",
      screenshot_pod: {},
      screenshot_api_state: false,
      screenshot_api_poller: null,
      thumbnail_image_url: "",
    };
  },

  computed: {
    logo_file() {
        return this.$store.getters.getThemeLogo().logo;
    }
  },

  methods: {
    podsList() {
        if(this.pods_api_state) {
            return;
        }
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token,
            client_id: user_data.client_id,
            lab_id: this.lab_id
        };
        this.pods_api_state = true;
        adminGetPodsList(params).then(response => {
            this.pods_api_state = false;
            this.pods = response.data.pods;
            if(this.pods.length > 0) {
                this.pods.map(function(lb){
                    return lb.action='icon-button-actions';
                });
            }
        });
    },

    palletsList() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token
      };
      adminGetPalletsList(params).then(response => {
        this.rgb_pallets = response.data.pallets;
      });
    },

    ledModal(pod_id) {
      this.selected_pod_id = pod_id;
      this.$bvModal.show('pallets-modal');
    },

    selectUnselectLED(value) {
      if(this.led_state == value) {
        this.led_state = '';
      }
      else {
        this.led_state = value;
      }
    },

    commandHandle(value, name, pod_id) {
      this.commandExecute(this.command_types[name], value, pod_id);
    },

    commandExecute(command_type, command_value, pod_id) {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        command_type: command_type,
        command_value: command_value,
        pod_ids: [pod_id]
      };
      adminRunCommand(params).then(response => {
        if(response.status) {
            this.$bvModal.hide('pallets-modal');
            this.makeToast("success", response.message);
            if(command_type == 'lockout' || command_type == 'unlockout') {
                let params1 = {
                    pod_disabled: (command_type == 'lockout' ? 'yes' : 'no')
                };
                setFlaskPodDisabled(params1).then(ds => {
                    console.log("FLASK DISABLED API CALLED : ", ds);
                });
                this.podsList();
            }
            else if(command_type == 'led') {
                this.led_state = "";
            }
        }
        else {
            this.mute_state = !this.mute_state;
            this.makeToast("error", response.message);
        }
      })
    },

    confirmDelete(pod_id) {
      this.delete_pod_id = pod_id;
      this.$bvModal.show('delete-modal');
    },

    deletePod() {
      let user_data = JSON.parse(localStorage.user_sess_data);
      let params = {
        token: user_data.token,
        pod_id: this.delete_pod_id
      };
      adminDeletePod(params).then(response => {
        if(response.status) {
          this.$bvModal.hide('delete-modal');
          this.makeToast("success", response.message);
          this.podsList();
        }
        else {
          this.makeToast("error", response.message);
        }
      });
    },

    makeToast(variant = "default", message) {
        //   this.$toast.open({
        //     message: message,
        //     type: variant,
        //     position: 'top-right',
        //     duration: 3000,
        //     dismissible: true
        //   });
        this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
    },

    setPodData(obj) {
        this[obj.key] = obj.value;
    },

    handleLockUnlock(pod_id_key) {
        let value = this.$refs[pod_id_key+'_lockout'].checked;
        if(value) {
            this.commandHandle(1, 'lockout', pod_id_key)
        }
        else {
            this.commandHandle(1, 'unlockout', pod_id_key)
        }
    },

    screenShotModal(pod_id, screenshot) {
      this.screenshot_pod_id = pod_id;
      this.screenshot_pod = screenshot;
      this.thumbnail_image_url = this.screenshot_pod.url + '/' + this.screenshot_pod.image;
      this.$bvModal.show('screenshot-modal');
      clearInterval(this.screenshot_api_poller);
      this.screenshot_api_poller = setInterval(() => {
        this.handlePodScreenshot();
      }, 8000);
    },
    handlePodScreenshot() {
        if(this.screenshot_pod_id !== "" && !this.screenshot_api_state) {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                pod_id: this.screenshot_pod_id
            };
            this.screenshot_api_state = true;
            adminGetPodScreenshot(params).then(response => {
                this.screenshot_api_state = false;
                if(response.status) {
                    this.screenshot_pod = response.data.screenshot;
                    // call image to load before display
                    const img = new Image();
                    img.src = this.screenshot_pod.url + '/' + this.screenshot_pod.image;
                    img.onload = () => {
                        this.thumbnail_image_url = img.src;
                    };

                    if(this.pods.length > 0) {
                        let _self = this;
                        this.pods.map(function(lb){
                            if(lb.pod_id_key == _self.screenshot_pod_id) {
                                return lb.pod.ScreenshotDetail = _self.screenshot_pod;
                            }
                            else {
                                return lb;
                            }
                        });
                    }
                }
            });
        }
    },
  },

  mounted() {
    this.podsList();
    this.palletsList();
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId == "delete-modal") {
        this.delete_pod_id = "";
      }
      if(modalId == "screenshot-modal") {
        this.screenshot_pod_id = "";
        this.screenshot_pod = {};
        clearInterval(this.screenshot_api_poller);
        this.screenshot_api_state = false;
      }
    });
    // check for pods list poller
    clearInterval(this.pods_poller);
    this.pods_poller = setInterval(() => {
        this.podsList();
    }, process.env.VUE_APP_PODS_LIST_DURATION);
  },
  beforeDestroy() {
    clearInterval(this.pods_poller);
    // clear screenshot interval variable
    clearInterval(this.screenshot_api_poller);
  }

};
</script>
<style scoped>
.admin-portal #admin-general-table {
    user-select: text !important;
}
.admin-portal #admin-general-table .pod-key-container {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
}
:deep(.modal.show .modal-dialog) {
    max-width: calc(100vw - 60px);
    width: calc(100vw - 60px);
    height: calc(100vh - 50px);
    max-height: calc(100vh - 50px);
}
:deep(.modal.show .modal-dialog .modal-content) {
    height: calc(100vh - 50px);
    max-height: calc(100vh - 50px);
}
:deep(#screenshot-modal) {
    overflow: hidden;
}
.thumbnail-box {
    width: 100%;
    height: 100%;
    background-size: 100% 100% !important;
}
:deep(.modal.show .modal-dialog .modal-content .modal-body) {
    background: #000;
    color: #dfdfdf;
    height: 100%;
}
.auto-close-screenshot {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0;
}
@media screen and (max-width: 425px) {
    :deep(.modal.show .modal-dialog) {
        max-width: unset;
        width: auto;
    }
}
</style>