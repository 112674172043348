<template>
    <div class="admin-pod-dashboard">
        <AdminPollingAndSockets />
        <div class="w-logo mb-1" v-if="is_save_seat">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
        </div>

        <div class="apd-sections" :class="[is_save_seat ? 'd-none' : '']">

            <!-- logs section -->
            <div class="apd-sections-item full animate__animated animate__fadeIn animate__delay-3s">

                <div class="apd-logs">

                    <!-- start of player feedback panel -->
                    <div class="apd-logs-item">
                        <div class="apd-logs-panel">
                            <div class="apd-logs-panel-header">
                                {{ nav_name }} - Player Feedback
                            </div>
                            <div class="apd-logs-panel-body">
                                <ul>
                                    <li class="apd-logs-panel-body-header">
                                        <div>
                                            Timestamp
                                        </div>
                                        <div>
                                            Username
                                        </div>
                                        <div>
                                            Message
                                        </div>
                                    </li>
                                    <div class="apd-logs-panel-body-scroll" id="feedback-logs-list">
                                        <li class="apd-logs-panel-body-data" v-for="feed in feedbacks" :key="feed.id">
                                            <div>
                                                {{ feed.timestamp }}
                                            </div>
                                            <div>
                                                {{ (typeof feed.player != 'undefined' && feed.player != null) ? feed.player.email : '' }}
                                            </div>
                                            <div>
                                                {{ feed.comment }}
                                            </div>
                                        </li>
                                        <li class="apd-logs-panel-body-data" v-if="feedbacks.length == 0">
                                            <div>No feedback at the moment</div>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- end of player feedback panel -->

                    <!-- start of player feedback panel -->
                    <div class="apd-logs-item mt-5">
                        <div class="apd-logs-panel">
                            <div class="apd-logs-panel-header">
                                {{ nav_name }} - Admin Logs
                            </div>
                            <div class="apd-logs-panel-body">
                                <ul>
                                    <li class="apd-logs-panel-body-header">
                                        <div>
                                            Timestamp
                                        </div>
                                        <div>
                                            Username
                                        </div>
                                        <div>
                                            Message
                                        </div>
                                    </li>
                                    <div class="apd-logs-panel-body-scroll" id="admin-logs-list">
                                        <li class="apd-logs-panel-body-data" v-for="log in logs" :key="log.id">
                                            <div>
                                                {{ log.timestamp }}
                                            </div>
                                            <div>
                                                {{ (typeof log.user != 'undefined' && log.user != null) ? log.user.email : '' }}
                                            </div>
                                            <div>
                                                {{ log.message }}
                                            </div>
                                        </li>
                                        <li class="apd-logs-panel-body-data" v-if="logs.length == 0">
                                            <div>No logs at the moment</div>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- end of player feedback panel -->

                </div>

            </div>
            <!-- end of logs section -->

            <!-- pod info section -->
            <div class="apd-sections-item half">

                <div class="w-logo mb-1">
                    <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
                </div>
                <h3 class="page-heading animate__animated animate__fadeInUp animate__delay-1s mb-4">Admin Dashboard</h3>

                <div v-if="nav_name !== ''" class="w-form-control apd-pod-info-nav animate__animated animate__fadeInUp animate__delay-2s mt-3">
                    <span class="apd-pod-info-nav-name">
                        {{ nav_name }}
                    </span>
                </div>

                <div class="w-form-control apd-pod-info animate__animated animate__fadeInUp animate__delay-2s mt-3" v-if="(xds_data.xd1 !== '' && xds_data.xd1 !== 'na') || (xds_data.xd2 !== '' && xds_data.xd2 !== 'na')">
                    <div class="apd-pod-info-left">
                        <i class="fa-solid fa-wave-square"></i>
                    </div>
                    <div class="apd-pod-info-right">
                        <template v-if="xds_data.xd1 !== '' && xds_data.xd1 !== 'na' && xds_data.xd1 !== 'start'">
                            <div class="apd-pod-info-right-button">
                                <div class="apd-pod-info-right-button-left">
                                    <object type="image/svg+xml" class="" :data="getXdButtonIcons(xds_data.xd1)" tabindex="-1"></object>
                                    {{ xds_icons[xds_data.xd1].xd }}
                                </div>
                                <div class="apd-pod-info-right-button-right">
                                    <label class="gen-switch lockunlock">
                                        <input type="checkbox" 
                                        :ref="pod_id+'_xd_'+xds_data.xd1" 
                                        :v-model="xds_data[xds_data.xd1+'_disabled']" 
                                        :checked="!xds_data[xds_data.xd1+'_disabled']" 
                                        @change="handleXdSwitching('xd1', xds_data.xd1, (xds_data[xds_data.xd1+'_disabled'] ? 0 : 1))"
                                        >
                                        <span class="gen-switch-slider gen-switch-round lockunlock"></span>
                                    </label>
                                </div>
                            </div>
                        </template>
                        <template v-if="xds_data.xd2 !== '' && xds_data.xd2 !== 'na' && xds_data.xd2 !== 'start'">
                            <div class="apd-pod-info-right-button">
                                <div class="apd-pod-info-right-button-left">
                                    <object type="image/svg+xml" class="" :data="getXdButtonIcons(xds_data.xd2)" tabindex="-1"></object>
                                    {{ xds_icons[xds_data.xd2].xd }}
                                </div>
                                <div class="apd-pod-info-right-button-right">
                                    <label class="gen-switch lockunlock">
                                        <input type="checkbox" 
                                        :ref="pod_id+'_xd_'+xds_data.xd2" 
                                        :v-model="xds_data[xds_data.xd2+'_disabled']" 
                                        :checked="!xds_data[xds_data.xd2+'_disabled']" 
                                        @change="handleXdSwitching('xd2', xds_data.xd2, (xds_data[xds_data.xd2+'_disabled'] ? 0 : 1))"
                                        >
                                        <span class="gen-switch-slider gen-switch-round lockunlock"></span>
                                    </label>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>

                <!-- start of volume section -->
                <div class="apd-volume-container col-md-12 mt-4 animate__animated animate__fadeInUp animate__delay-3s">
                    <div class="apd-volume mb-3">
                        <b-input-group class="d-flex text-center align-items-center">
                            <b-input-group-prepend class="mute-icons">
                                <b-icon 
                                class="icon h4" 
                                icon="volume-down-fill" 
                                @click="volumeMuteUnmute('mute')"
                                >
                                </b-icon>
                            </b-input-group-prepend>
                            <b-form-input 
                            type="range" 
                            class="volume-input" 
                            min="0" 
                            max="50" 
                            step="1" 
                            tabindex="-1" 
                            v-model="pod_volume"
                            @change="volumeChangePodDashboard"
                            v-b-tooltip="{ title: (pod_volume * 2 + '%'), placement: 'top', trigger: 'hover focus', customClass: 'volume-tooltip' }"
                            >
                            </b-form-input>
                            <b-input-group-append class="mute-icons">
                                <b-icon 
                                class="icon h4" 
                                icon="volume-up-fill" 
                                @click="volumeMuteUnmute('unmute')"
                                >
                                </b-icon>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
                <!-- end of voulme section -->

                <!-- start of audio section -->
                <div class="apd-audio col-md-12 mt-4 animate__animated animate__fadeInUp animate__delay-3s">
                    <label class="apt-audio-title">Toggle Test Audio</label>
                    <label class="gen-switch playpause">
                        <input type="checkbox" 
                        ref="toggle_audio" 
                        @click="toggleAudio"
                        >
                        <span class="gen-switch-slider gen-switch-round playpause"></span>
                    </label>
                    <figure class="d-none">
                        <audio
                        ref="audio_player"
                        controls 
                        loop
                        :src="audio_url"
                        >
                        </audio>
                    </figure>
                </div>
                <!-- end of audio section -->

                <!-- start of logs entery form -->
                <div class="apd-log-entery col-md-12 mt-5 animate__animated animate__fadeInUp animate__delay-3s">
                    <b-form inline @submit.stop.prevent class="m-0 p-0">
                        <div class="row">
                            <div class="d-flex mb-1 col-md-12 flex-column">
                                <div class="w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100">
                                    <b-form-textarea
                                        v-model="form.comment"
                                        id="comment"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 w-textarea"
                                        placeholder="type new log entry here"
                                        no-resize
                                    ></b-form-textarea>
                                </div>
                            </div>
                        </div>
                        <div
                        class="
                        w-form-control
                        animate__animated animate__fadeInUp animate__delay-3s m-0 p-0
                        "
                        >
                            <a
                            href="javascript:void(0)"
                            class="flex-fill" 
                            @click="handleLogReq"
                            >Submit Log Entry for {{ nav_name }}</a
                            >
                        </div>

                    </b-form>
                </div>
                <!-- end of log entery form -->

            </div>
            <!-- end of pod info section -->

            <!-- pods list section -->
            <div class="apd-sections-item full animate__animated animate__fadeIn animate__delay-3s">

                <div class="apd-logs apd-pods-list">

                    <!-- start of pods list panel -->
                    <div class="apd-logs-item">
                        <div class="apd-logs-panel">
                            <div class="apd-logs-panel-header">
                                <span>Select Target Gamepods</span>
                                <span>
                                    Select All
                                    <label class="gen-switch tickcross">
                                        <input type="checkbox" 
                                        ref="select_all_pods" 
                                        @change="handleSelectPods('all')"
                                        >
                                        <span class="gen-switch-slider gen-switch-round tickcross"></span>
                                    </label>
                                </span>
                            </div>
                            <div class="apd-logs-panel-body">
                                <ul>
                                    <li class="apd-logs-panel-body-header">
                                        <div>
                                            <span>Nav ID</span>
                                            <span>Selected</span>
                                        </div>
                                        <div>
                                            <span>Nav ID</span>
                                            <span>Selected</span>
                                        </div>
                                        <div>
                                            <span>Nav ID</span>
                                            <span>Selected</span>
                                        </div>
                                    </li>
                                    <div class="apd-logs-panel-body-scroll">
                                        <li class="apd-logs-panel-body-data">
                                            <div v-for="pod in pods" :key="pod.id">
                                                <span>{{pod.license}}</span>
                                                <label class="gen-switch tickcross">
                                                    <input type="checkbox" 
                                                    :ref="pod.pod_id_key+'_select_pod'" 
                                                    :class="pod.id+'-pod-id'"
                                                    :checked="selected_pod_ids.indexOf(pod.pod_id_key) !== -1" 
                                                    @change="handleSelectPods('', pod.pod_id_key, pod.pod_id_key+'_select_pod')"
                                                    >
                                                    <span class="gen-switch-slider gen-switch-round tickcross"></span>
                                                </label>
                                            </div>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- end of pods list panel -->

                    <div class="apd-pod-buttons">
                        <div class="apd-pod-buttons-header">
                            Select Target Action | <span> Affects All Selected Gamepods Above</span>
                        </div>
                        <div class="apd-pod-buttons-body">
                            <button @click="ledModal">Change LED</button>
                            <button @click="commandHandle(1, 'seat')">Save Seat</button>
                            <button @click="commandHandle(1, 'logout')">Logout</button>
                            <button @click="commandHandle(1, 'reboot')">Reboot</button>
                            <button @click="loadGames('xb')">
                                <object type="image/svg+xml" class="" :data="xb_icon" tabindex="-1"></object>
                                Show Xbox
                            </button>
                            <button @click="loadGames('pc')">
                                <object type="image/svg+xml" class="" :data="pc_icon" tabindex="-1"></object>
                                Show PC
                            </button>
                            <button @click="loadGames('sw')">
                                <object type="image/svg+xml" class="" :data="sw_icon" tabindex="-1"></object>
                                Show Switch
                            </button>
                            <button @click="loadGames('ps')">
                                <object type="image/svg+xml" class="" :data="ps_icon" tabindex="-1"></object>
                                Show PS5
                            </button>
                        </div>

                    </div>

                </div>
                
            </div> 
            <!-- end pods list section -->

        </div>

        <!-- start of save seat section -->
        <template v-if="is_save_seat">
            <AdminSaveSeatWidget @handleLogout="initSaveSeatLogout($event)" @initSaveSeat="initSaveSeat($event)" />
        </template>
        <!-- end of save seat section -->

        <!-- start of pallets modal -->
        <b-modal id="pallets-modal" dialog-class="admin-portal-modal" header-class="admin-portal-bg secondary px-3 py-2" hide-footer>
            <template #modal-title>
                Update LED Settings
            </template>
            <div class="d-block text-center theme-color-buttons">
                <button
                    v-for="clr in rgb_pallets"
                    :key="clr.id"
                    type="button"
                    class="btn bg mb-3"
                    :style="{ background: clr.value, color: '#fff' }"
                    :class="[ led_state !== clr.id ? 'theme-opacity' : '' ]"
                    @click="selectUnselectLED(clr.id)"
                >
                    {{ clr.value.toUpperCase() }}
                </button>
            </div>
            <div class="d-flex" v-if="led_state !== ''">
                <button 
                class="modal-button w-100"
                @click="commandHandle(led_state, 'led1')"
                >Change LED1</button>
            </div>
            <div class="d-flex mt-2" v-if="led_state !== ''">
                <button 
                class="modal-button w-100"
                @click="commandHandle(led_state, 'led2')"
                >Change LED2</button>
            </div>
        </b-modal>

    </div>
</template>
  
<script>
import { global_config } from "@/config/config.js";
import { getXdButtonIcons } from "../services/Guard";
import { getXdTypes, getPodVolume, setFlaskPodVolume, setFlaskPodXdDisabled, setFlaskLED1, setFlaskLED2, setFlaskRebootPod } from "../services/FlaskService";
import { getPodDetails, setPodVolume } from "../services/PlayerService";
import { adminGetFeedbackList, adminGetLogsList, adminSaveLog, adminGetClientDetails, adminGetPodsList, adminGetPalletsList, adminRunCommand } from "../services/AdminService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required } from 'vuelidate/lib/validators';
import xb_icon from "../assets/images/icon_xbox.svg";
import sw_icon from "../assets/images/icon_switch.svg";
import pc_icon from "../assets/images/icon_pc.svg";
import ps_icon from "../assets/images/icon_ps5.svg";
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import AdminSaveSeatWidget from "@/components/AdminSaveSeatWidget.vue";
import AdminPollingAndSockets from "@/views/admin/AdminPollingAndSockets.vue";

export default {
    name: "AdminPodDashboardWidget",
    components: { AdminSaveSeatWidget, AdminPollingAndSockets },
    data() {
        return {
            xds_data: {
                "xd1" : "start", 
                "xd2" : "start", 
                "xb_disabled": false, 
                "ps_disabled": false, 
                "sw_disabled": false, 
                "pc_disabled": false, 
                "pod_disabled": false
            },
            xds_icons: global_config.XD_ICONS,
            pod_data: null,
            pod_volume: 0,
            nav_name: '',
            form: {
                comment : ''
            },
            xb_icon: xb_icon,
            sw_icon: sw_icon,
            pc_icon: pc_icon,
            ps_icon: ps_icon,
            api_state: false,
            feedbacks: [],
            feedback_page: 1,
            feedback_last_page: 1,
            feedback_length: 10,
            feedback_total: 0,
            api_state1: false,
            logs: [],
            logs_page: 1,
            logs_last_page: 1,
            logs_length: 10,
            logs_total: 0,
            user_timezone: moment.tz.guess(),
            mtz: moment_timezone,
            volume_polling: null,
            api_state_general: false,
            audio_url: "",
            pods: [],
            selected_pod_ids:[],
            command_types: {
                'logout' : 'logout', 
                'reboot' : 'reboot',
                'led1' : 'led1_lock',
                'led2' : 'led2_lock',
                'seat' : 'save_seat'
            },
            rgb_pallets : [],
            led_state: "",
            is_save_seat: false
        };
    },
    props: ['pod_id', 'logo_file', 'needs_save_seat'],
    validations: {
        form : {
            comment : {required}
        }
    },
    methods: {
        getXdButtonIcons(xd) {
            return getXdButtonIcons(xd);
        },
        getPodXdTypes() {
            // get xd types for buttons
            getXdTypes().then(xd => {
                if(xd.data !== undefined) {
                    this.xds_data = xd.data;
                }
            });
        },
        podDetails() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            this.nav_name = pod_data.nav_name;
            let params = {
                session_id_key : pod_data.session_id_key,
                session_id : pod_data.session_id_key,
                token: user_data.token
            };
            getPodDetails(this.pod_id, params).then(response => {
                if(response.status) {
                    this.pod_data = response.data.pod;
                    this.pod_volume = response.data.pod.volume;
                }
            });
        },
        playerFeedbacks() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                page: this.feedback_page,
                length: this.feedback_length
            };
            this.api_state = true;
            adminGetFeedbackList(params).then(response => {
                this.api_state = false;
                this.feedbacks = response.data.feedback;
                this.feedback_page = response.data.current_page + 1;
                this.feedback_last_page = response.data.last_page;
                this.feedback_total = response.data.total_rows;
            })
        },
        adminLogs() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                page: this.logs_page,
                length: this.logs_length
            };
            this.api_state1 = true;
            adminGetLogsList(params).then(response => {
                this.api_state1 = false;
                this.logs = response.data.logs;
                this.logs_page = response.data.current_page + 1;
                this.logs_last_page = response.data.last_page;
                this.logs_total = response.data.total_rows;
            })
        },
        handleLogReq() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$root.$emit("toast_message", {'type' : 'error', 'message' : 'Please fill all required fields'});
            }
            else {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                let data = this.form;
                data.session_id_key = pod_data.session_id_key;
                data.token = user_data.token;
                data.edit_id = "";
                data.user_timezone = this.user_timezone;
                adminSaveLog(data).then(response => {
                    if(response.status){
                        this.$root.$emit("toast_message", {'type' : 'success', 'message' : response.message});
                        this.form.comment = "";
                        this.logs_page = 1;
                        this.logs_last_page = 1;
                        this.logs_total = 0;
                        this.logs = [];
                        this.adminLogs();
                    } 
                    else {
                        this.$root.$emit("toast_message", {'type' : 'error', 'message' : response.message});
                    }
                });
            }
        },
        volumeMuteUnmute(type) {
            if(type == 'mute') {
                this.pod_volume = 0;
                this.volumeChange(this.pod_volume);
            }
            else if(type == 'unmute') {
                this.pod_volume = 100;
                this.volumeChange(this.pod_volume);
            }
        },
        volumeChangePodDashboard() {
            this.volumeChange(this.pod_volume);
        },
        volumeChange(volume, source="web") {
            // clear interval first
            clearInterval(this.volume_polling);
            this.api_state_general = true;
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                pod_id_key: this.pod_id,
                volume: volume,
            };
            let flag = true;
            if(source == "" && volume == this.pod_volume) {
                flag = false;
            }
            
            if(flag) {
                setPodVolume(params).then(response => {
                    this.api_state_general = false;
                    if(response.status) {
                        this.pod_volume = volume;
                        // init volume change on pod as well
                        if(source !== "") {
                            this.$root.$emit("toast_message", {'type' : 'success', 'message' : response.message});
                            let params = {
                                volume : this.pod_volume
                            };
                            setFlaskPodVolume(params).then(vol_web => {
                                setTimeout(() => {
                                    vol_web;
                                    this.pollPodVolume();  
                                }, 3000);
                            });
                        }
                        else {
                            this.pollPodVolume();
                        }
                    }
                    else {
                        this.$root.$emit("toast_message", {'type' : 'error', 'message' : response.message});
                        this.pollPodVolume();
                    }
                })
            }
            else {
                this.api_state_general = false;
                this.pollPodVolume();
            }
        },
        pollPodVolume () {
            this.volume_polling = setInterval(() => {
                getPodVolume().then(vol => {
                    if(vol.data.vol_now !== undefined && vol.data.vol_now !== "") {
                        this.volumeChange(vol.data.vol_now, "");
                    }
                });
            }, process.env.VUE_APP_POLLING_TIME_POD_VOLUME_CHECK);
        },
        clientDetails() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: user_data.client_id
            };
            adminGetClientDetails(params).then(response => {
                this.audio_url = response.data.clients[0].audio_url;
            });
        },
        toggleAudio() {
            if(this.audio_url !== '') {
                let audio = this.$refs.audio_player;
                if(this.$refs.toggle_audio.checked) {
                    audio.play();
                }
                else {
                    audio.pause();
                }
            }
        },
        handleXdSwitching(xd_number, name,value) {
            let params = {
                xd: xd_number,
                disable: value
            };
            setFlaskPodXdDisabled(params).then(dis => {
                dis;
                getXdTypes().then(xd => {
                    if(xd.data !== undefined) {
                        this.xds_data = xd.data;
                    }
                });
            });
        },
        podsList() {
            // let _self = this;
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                token: user_data.token,
                client_id: user_data.client_id,
                pod_session : pod_data.session_id_key
            };
            adminGetPodsList(params).then(response => {
                this.pods = response.data.pods;
                if(this.pods.length > 0) {
                    this.pods.map(function(lb){
                        // if(_self.pod_id == lb.pod_id_key) {
                        //     _self.selected_pod_ids.push(lb.pod_id_key);
                        // }
                        return lb.action='icon-button-actions';
                    });
                    // this.pods = this.pods.filter(function(pd){
                    //     return pd.pod_id_key == pod_data.pod_id_key;
                    // });
                }
            });
        },
        handleSelectPods(type="", pod_id, ref_id) {
            let _self = this;
            if(type == "all") {
                this.selected_pod_ids = [];
                if(this.$refs.select_all_pods.checked) {
                    this.pods.forEach((item) => {
                        _self.selected_pod_ids.push(item.pod_id_key);
                    });
                }
                // else {
                //     this.selected_pod_ids.push(this.pod_id);
                // }
            }
            else {
                if(this.$refs[ref_id][0].checked) {
                    this.selected_pod_ids.push(pod_id);
                }
                else {
                    this.selected_pod_ids.splice(this.selected_pod_ids.indexOf(pod_id), 1);
                }
                if(this.pods.length == this.selected_pod_ids.length) {
                    this.$refs.select_all_pods.checked = true;
                }
                else {
                    this.$refs.select_all_pods.checked = false;
                }
            }
            localStorage.setItem('admin_selected_pods', JSON.stringify({'items' : this.selected_pod_ids}));
        },
        palletsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetPalletsList(params).then(response => {
                this.rgb_pallets = response.data.pallets;
            });
        },
        ledModal() {
            this.$bvModal.show('pallets-modal');
        },

        selectUnselectLED(value) {
            if(this.led_state == value) {
                this.led_state = '';
            }
            else {
                this.led_state = value;
            }
        },

        commandHandle(value, name) {
            this.commandExecute(this.command_types[name], value);
        },

        commandExecute(command_type, command_value) {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                token: user_data.token,
                command_type: command_type,
                command_value: command_value,
                pod_ids: this.selected_pod_ids,
                request_pod_id: pod_data.pod_id_key
            };
            let _self = this;
            if(this.selected_pod_ids.length > 0) {
                adminRunCommand(params).then(response => {
                    if(response.status) {
                        this.$bvModal.hide('pallets-modal');
                        this.$root.$emit("toast_message", {'type' : 'success', 'message' : response.message});
                        
                        if(_self.selected_pod_ids.indexOf(_self.pod_id) !== -1) { 
                            if(command_type == 'led1_lock') {
                                this.led_state = "";
                                let params = {
                                    led1_lock: response.data.command_value
                                };
                                setFlaskLED1(params, 'lock').then(lc => {
                                    console.log("LED1 CHANGE FLASK API CALLED : ", lc);
                                });
                            }
                            else if(command_type == 'led2_lock') {
                                this.led_state = "";
                                let params = {
                                    led2_lock: response.data.command_value
                                };
                                setFlaskLED2(params, 'lock').then(lc => {
                                    console.log("LED2 CHANGE FLASK API CALLED : ", lc);
                                });
                            }
                            else if(command_type == 'logout') {
                                this.$emit('handleLogout', true);
                            }
                            else if(command_type == 'reboot') {
                                setFlaskRebootPod().then(reb => {
                                    console.log("REBOOT POD API IS CALLED : ", reb);
                                });
                                this.$emit('handleLogout', true);
                            }
                            else if(command_type == 'save_seat') {
                                this.initSaveSeat(true);
                            }
                        }
                    }
                    else {
                        this.$root.$emit("toast_message", {'type' : 'error', 'message' : response.message});
                    }
                });
            }
            else {
                this.$root.$emit("toast_message", {'type' : 'warning', 'message' : 'no-pod-selected'});
            }
        },
        loadGames(xd) {
            if(this.selected_pod_ids.length > 0) {
                localStorage.setItem('admin_selected_pods', JSON.stringify({'items' : this.selected_pod_ids}));
                this.$router.push('/admin/pod_games/' + xd);
            }
            else {
                this.$root.$emit("toast_message", {'type' : 'warning', 'message' : 'no-pod-selected'});
            }
        },
        initSaveSeat(value) {
            if(this.selected_pod_ids.length > 0) {
                this.is_save_seat = value;
            }
            else {
                this.$root.$emit("toast_message", {'type' : 'warning', 'message' : 'no-pod-selected'});
            }
        },
        initSaveSeatLogout(value) {
            this.$emit('handleLogout', value);
        },
    },
    created() {
        this.getPodXdTypes();
        this.podDetails();
        this.playerFeedbacks();
        this.adminLogs();
        this.pollPodVolume();
        this.clientDetails();
        this.podsList();
        this.palletsList();
        // check if save seat in progress
        if(typeof localStorage.admin_save_seat !== 'undefined') {
            let save_seat_data = JSON.parse(localStorage.getItem('admin_save_seat'));
            if(save_seat_data.active == 1) {
                this.selected_pod_ids = [this.pod_id];
                localStorage.setItem('admin_selected_pods', JSON.stringify({'items' : [this.pod_id]}));
                this.initSaveSeat(true);
            }
        }
        // check if pods already selected
        if(typeof localStorage.admin_selected_pods !== 'undefined') {
            this.selected_pod_ids = JSON.parse(localStorage.getItem('admin_selected_pods')).items;
        }
        // check if save seat needed
        if(this.needs_save_seat) {
            this.selected_pod_ids = [this.pod_id];
            localStorage.setItem('admin_selected_pods', JSON.stringify({'items' : [this.pod_id]}));
            this.initSaveSeat(true);
        }

        // capture admin websocket messages
        if(
            Object.prototype.hasOwnProperty.call(this.$root, "_events") && 
            Object.prototype.hasOwnProperty.call(this.$root._events, "admin_save_seat")
        ) {
            this.$root.$off("admin_save_seat");
        }
        this.$root.$on('admin_save_seat', (message) => {
            if(Object.prototype.hasOwnProperty.call(message, "admin_websocket") && message.admin_websocket) {
                if(message.admin_websocket.command_type == 'save_seat') {
                    this.selected_pod_ids = [this.pod_id];
                    localStorage.setItem('admin_selected_pods', JSON.stringify({'items' : [this.pod_id]}));
                    this.initSaveSeat(true);
                }
            }
        });
    },
    beforeDestroy() {
        clearInterval(this.volume_polling);
    },
    mounted() {
        document.getElementById('feedback-logs-list').onscroll = () => {
            let bottomOfWindow = document.getElementById('feedback-logs-list').scrollTop + document.getElementById('feedback-logs-list').offsetHeight === document.getElementById('feedback-logs-list').scrollHeight;
            if (bottomOfWindow && !this.api_state && this.feedback_page <= this.feedback_last_page) {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                let params = {
                    session_id_key : pod_data.session_id_key,
                    token: user_data.token,
                    page: this.feedback_page,
                    length: this.feedback_length
                };
                this.api_state = true;
                adminGetFeedbackList(params).then(response => {
                    this.api_state = false;
                    this.feedback_page = response.data.current_page + 1;
                    this.feedback_last_page = response.data.last_page;
                    this.feedback_total = response.data.total_rows;
                    this.feedbacks = this.feedbacks.concat(response.data.feedback);
                })
            }
        };
        document.getElementById('admin-logs-list').onscroll = () => {
            let bottomOfWindow = document.getElementById('admin-logs-list').scrollTop + document.getElementById('admin-logs-list').offsetHeight === document.getElementById('admin-logs-list').scrollHeight;
            if (bottomOfWindow && !this.api_state1 && this.logs_page <= this.logs_last_page) {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                let params = {
                    session_id_key : pod_data.session_id_key,
                    token: user_data.token,
                    page: this.feedback_page,
                    length: this.feedback_length
                };
                this.api_state = true;
                adminGetLogsList(params).then(response => {
                    this.api_state = false;
                    this.logs_page = response.data.current_page + 1;
                    this.logs_last_page = response.data.last_page;
                    this.logs_total = response.data.total_rows;
                    this.logs = this.logs.concat(response.data.logs);
                })
            }
        };
    }
}
</script>
<style scoped>
.admin-pod-dashboard .page-heading {
    font-size: 26px;
}
.admin-pod-dashboard .apd-sections {
    min-height: 67vh;
    display: flex;
    flex-direction: row;
    column-gap: 60px;
    max-height: 85vh;
}
.admin-pod-dashboard .apd-sections .apd-sections-item {
    height: auto;
}
.admin-pod-dashboard .apd-sections .apd-sections-item.full {
    width: calc(75% / 2);
}
.admin-pod-dashboard .apd-sections .apd-sections-item.half {
    width: 25%;
}
.admin-pod-dashboard .apd-pod-info {
    width: 100%;
    text-align: center;
    margin: auto;
    border: 2px solid var(--client-primary);
    border-radius: 8px;
    justify-content: flex-start !important;
    column-gap: 0px !important;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-left {
    width: 10%;
    background: var(--client-primary);
    display: flex;
    padding: 15px 10px;
    justify-content: center;
    align-items: center;
    color: #d8d8d8;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 700;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-right {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 15px 30px;
    row-gap: 15px;
    min-height: 105.66px;
    justify-content: center;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-right .apd-pod-info-right-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-right .apd-pod-info-right-button .apd-pod-info-right-button-left {
    color: #d8d8d8;
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans';
    column-gap: 10px;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-right .apd-pod-info-right-button .apd-pod-info-right-button-left object {
    width: 40px;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-right .apd-pod-info-right-button .apd-pod-info-right-button-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #d8d8d8;
    font-weight: 700;
    column-gap: 10px;
}
.admin-pod-dashboard .apd-pod-info .apd-pod-info-disabled {
    opacity: 0.65;
}
.admin-pod-dashboard .apd-pod-info-nav .apd-pod-info-nav-name {
    background: var(--client-primary);
    padding: 7px;
    color: #d8d8d8;
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 700;
    border-radius: 8px;
}


.admin-pod-dashboard .apd-volume-container {
    margin: auto;
    width: 100%;
}
.admin-pod-dashboard .apd-volume-container .apd-volume {
    display: flex;
    align-items: center;   
}
.admin-pod-dashboard .apd-volume-container .apd-volume .mute-icons .icon{
    margin: 0px;
    font-size: 26px;
    color: #fff;
    margin-top: 3px;
}
.admin-pod-dashboard .apd-audio {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    align-items: center;
    justify-content: center;
}

.admin-pod-dashboard .apd-audio label.apt-audio-title {
    font-size: 16px;
    color: #d8d8d8;
    font-weight: 500;
}
/* start of general switches */
.gen-switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 34px;
}
.gen-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.gen-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7f7f7f;
    -webkit-transition: .4s;
    transition: .4s;
}  
.gen-switch-slider:before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    left: 0px;
    bottom: -2px;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: var(--client-hover);
    font-family: "FontAwesome";
    display: flex;
    align-items: center;
    justify-content: center;;
    color: #d8d8d8;
}
.gen-switch input:checked + .gen-switch-slider:before {
    -webkit-transform: translateX(31px);
    -ms-transform: translateX(31px);
    transform: translateX(31px);
    background-color: var(--client-primary);
}
.gen-switch-slider.gen-switch-round {
    border-radius: 34px;
}
.gen-switch-slider.gen-switch-round:before {
    border-radius: 50%;
}
.gen-switch-slider.lockunlock:before {
    content: "\f023";
}
.gen-switch.lockunlock input:checked + .gen-switch-slider.lockunlock:before {
    content: "\f13e";
}
.gen-switch-slider.tickcross:before {
    content: "\f00d";
}
.gen-switch.tickcross input:checked + .gen-switch-slider.tickcross:before {
    content: "\f00c";
}
.gen-switch-slider.playpause:before {
    content: "\f04c";
}
.gen-switch.playpause input:checked + .gen-switch-slider.playpause:before {
    content: "\f04b";
}
/* end of general switches */
.admin-pod-dashboard .w-form-control.w-input-fields textarea {
    height: 170px;
    max-height: unset;
}
.admin-pod-dashboard .apd-logs {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    column-gap: 40px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    row-gap: 10px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-header {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    font-weight: 700;
    color: #d8d8d8;
    font-family: 'Open Sans';
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll {
    max-height: 29.5vh;
    min-height: 29.5vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll::-webkit-scrollbar {
    width: 15px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll::-webkit-scrollbar-track {
    background: #d3d3d3;
    border-radius: 10px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll::-webkit-scrollbar-thumb {
    background: rgb(244 130 38);
    border: 4px solid #d3d3d3;
    border-radius: 10px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(244 130 38);
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul {
    list-style: none;
    padding: 0px 0px 0px 7px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li {
    background: transparent;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 500;
    color: #d8d8d8;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li.apd-logs-panel-body-header {
    border-bottom: 2px solid var(--client-primary);
    margin: 0px !important;
    padding: 0px 0px 7px 0px;
    align-items: self-end;
    font-size: 14px;
    font-weight: 600;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li > div:nth-child(1),
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li > div:nth-child(2) {
    width: 25%;
}
.admin-pod-dashboard .apd-logs .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li > div:nth-child(3) {
    width: 50%;
}

/* start of pods list section */
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul {
    padding: 0px 7px 0px 0px;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;
    font-weight: 700;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li.apd-logs-panel-body-header {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding-right: 15px;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li > div {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    column-gap: 20px;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li > div > span:first-child {
    width: 30%;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body > ul li:not(.apd-logs-panel-body-header) > div > span:first-child {
    text-align: center;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-header > span:last-child {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 15px;
}
.admin-pod-dashboard .apd-pods-list .apd-logs-item .apd-logs-panel .apd-logs-panel-body .apd-logs-panel-body-scroll {
    min-height: 42vh;
    max-height: 42vh;
    overflow-y: scroll;
}
/* end of pods list section */

/* start of pod buttons section */
.admin-pod-dashboard .apd-pod-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 25px;
    padding-right: 7px;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 700;
    color: #d8d8d8;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 2px solid var(--client-primary);
    margin-bottom: 15px;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-header > span {
    font-size: 12px;
    margin-left: 5px;
    margin-top: 3px;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-body > button {
    width: calc(100%/4 - 17px);
    padding: 10px 8px;
    background: transparent;
    border: 2px solid var(--client-primary);
    border-radius: 7px;
    color: #d8d8d8;
    font-size: 14px;
    font-family: 'Open Sans';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-body > button object {
    width: 45px;
    pointer-events: none;
}
.admin-pod-dashboard .apd-pod-buttons .apd-pod-buttons-body > button:hover {
    background: var(--client-primary);
}
/* end of pod buttons section */
</style>
<style>
body:has(div.admin-pod-dashboard) {
    overflow: hidden;
}
</style>